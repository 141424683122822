import React, {Component} from 'react';
import {connect} from 'react-redux'
import Form from '../forms/Form'
import {dateFormatter, getNumberFormatter} from "../grid/ColumnHelpers";
import { getTableRenderer } from "../bais/common";

const getFormFieldCfgs = ({ metadata }) => {
  const tabI = {
    multiFieldCfgs: [{
      name: 'datums',
      label: 'Datums',
      renderer: dateFormatter
    }, {
      name: 'viela',
      label: 'Viela'
    }, {
      name: 'koncentracija',
      label: 'Koncentrācija'
    }, {
      name: 'mervieniba',
      label: 'Mērvienība'
    }],
  }
  return [
    {
      name: 'gads',
      label: 'Gads',
    }, {
      name: 'npk',
      label: 'Npk.',
    }, {
      name: 'report_id',
      label: 'Report ID',
    }, {
      name: 'code',
      label: 'Kods',
    }, {
      name: 'name',
      label: 'Ūdenstilpes nosaukums',
    }, {
      name: 'org_nosaukums',
      label: 'Organizācijas (operatora) nosaukums'
    }, {
      name: 'adrese',
      label: 'Atsevišķās ražotnes (objekta) nosaukums un faktiskā adrese',
    }, {
      name: 'tips',
      label: 'Novadīšanas vieta',
    }, {
      name: 'daudzums',
      label: 'Daudzums',
    }, {
      name: 'tab_e',
      label: 'Paliekošais piesārņojums',
      renderer: getTableRenderer([{
          dataField: 'viela',
          text: 'Viela'
        }, {
          dataField: 'daudzums',
          text: 'Daudzums, t/gadā',
          headerStyle: { width: 140 }
        }, {
          dataField: 'vkn_koncentracija',
          text: 'Koncentrācija, µg/l',
          formatter: getNumberFormatter(6),
          style: (cell, row) => {
            if (row.ir_parsniegts) {
              return {
                backgroundColor: row.krasa
              }
            }
            return {}
          },
          headerStyle: { width: 140 }
        }, {
          dataField: 'vkn_robeza',
          text: 'VKN, µg/l',
          renderer: getNumberFormatter(6),
          headerStyle: { width: 100 }
        }], { keyField: 'kods' })
    }, {
      ...tabI,
      name: 'tab_i1',
      label: 'Monitoringa rezultāti augšpus no emisijas vietas',
    }, {
      ...tabI,
      name: 'tab_i2',
      label: 'Monitoringa rezultāti lejpus no emisijas vietas',
    }
  ]};

class UdensNovSpecific extends Component {

  render() {
    const {udensNovSpecific, metadata} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs(metadata)
    }

    const {selectedItem} = udensNovSpecific

    return (
      <div className="form-wrapper-pane">
        <Form
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onStartEdit={null}
          sizeLabel={3}
        />
      </div>
    )
  }
}

const mapStateToProps = ({udensNovSpecific, metadata}) => ({udensNovSpecific, metadata})

export default connect(mapStateToProps)(UdensNovSpecific)
